import React, { useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { LanguageContext } from "./LanguageContext";
import { Navigation } from "./navigation";
import { Banner } from "./banner";
import { Features } from "./features";
import { About } from "./about";
import { Services } from "./services";
import { Gallery } from "./gallery";
import { Contact } from "./contact";
import CallButton from "./CallButton";

const HomePage = () => {
    const { lang } = useParams();
    const navigate = useNavigate();
    const { changeLanguage, texts } = useContext(LanguageContext);

    // Set the language based on the URL parameter when the component mounts
    useEffect(() => {
        if (lang) {
            changeLanguage(lang);
        } else {
            // Default to 'en' if no language is specified in the URL
            navigate('/en');
        }
    }, [lang, changeLanguage, navigate]);

    const handleLanguageChange = (newLang) => {
        changeLanguage(newLang);
        navigate(`/${newLang}`);
    };

    return (
        <div>
            <Helmet>
                <title>{texts.Meta?.title || "Home Page"}</title>
                <meta name="description" content={texts.Meta?.description || "Welcome to our website"} />
                <meta property="og:title" content={texts.Meta?.title || "Home Page"} />
                <meta property="og:description" content={texts.Meta?.description || "Welcome to our website"} />
                <meta property="og:type" content="website" />
                <meta property="og:image" content={texts.Meta?.image || "/default-image.jpg"} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={texts.Meta?.title || "Home Page"} />
                <meta name="twitter:description" content={texts.Meta?.description || "Welcome to our website"} />
                <meta name="twitter:image" content={texts.Meta?.image || "/default-image.jpg"} />
            </Helmet>

            <CallButton phoneNumber={texts.Contact.phone} />
            <Navigation handleLanguageChange={handleLanguageChange} currentLanguage={lang} data={texts.Navigation} />
            <Banner data={texts.Banner} />
            <Features data={texts.Features} />
            <About data={texts.About} />
            <Services data={texts.Services} />
            <Gallery data={texts.Gallery} />
            <Contact data={texts.Contact} />
        </div>
    );
};

export default HomePage;
