import React from 'react';

const CallButton = ({ phoneNumber }) => {
    if (!phoneNumber) return null; // Prevent rendering if phoneNumber is undefined

    return (
        <div className="call-button-container">
            <div className="call-button-ring"></div>
            <a
                href={`tel:${phoneNumber}`}
                className="call-button"
                aria-label={`Call ${phoneNumber}`}
                title={`Call ${phoneNumber}`}
            >
                <i className="fa fa-phone" aria-hidden="true"></i>
            </a>
        </div>
    );
};

export default CallButton;
