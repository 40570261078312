import React from "react";
import { Helmet } from "react-helmet";

export const Services = (props) => {
  const { title, sub_title, services_items } = props.data;

  return (
      <div id="services" className="text-center smooth-scroll-container">
        <Helmet>
          <title>{title || "Our Services"}</title>
          <meta name="description" content={sub_title || "Description of our services"} />
          <meta property="og:title" content={title || "Our Services"} />
          <meta property="og:description" content={sub_title || "Description of our services"} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={window.location.href} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={title || "Our Services"} />
          <meta name="twitter:description" content={sub_title || "Description of our services"} />
        </Helmet>
        <div className="container">
          <div className="section-title">
            <h2>{title}</h2>
            <p>{sub_title}</p>
          </div>
          <div className="row">
            {services_items
                ? services_items.map((d, i) => (
                    <div key={`${d.name}-${i}`} className="col-md-4 d-flex flex-row justify-content-center">
                      <div className="service_image_container">
                        <img src={d.img} alt={d.name || "Service Image"} />
                      </div>
                      <div className="service-desc">
                        <h3>{d.name}</h3>
                        <p>{d.text}</p>
                      </div>
                    </div>
                ))
                : "loading"}
          </div>
        </div>
      </div>
  );
};
