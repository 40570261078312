import React from "react";

export const Features = ({ data }) => {
    return (
        <div id="features" className="text-center smooth-scroll-container">
            <div className="container">
                <div className="col-md-10 col-md-offset-1 section-title">
                    <h2>{data?.title || "Features"}</h2>
                </div>
                <div className="row">
                    {data?.features_items ? (
                        data.features_items.map((item, index) => (
                            <div key={`${item.title}-${index}`} className="col-xs-6 col-md-3 mb-3">
                                <i className={item.icon} aria-hidden="true"></i>
                                <h3>{item.title}</h3>
                                <p>{item.text}</p>
                            </div>
                        ))
                    ) : (
                        <div className="loading-container">
                            <p>Loading features...</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
