import React, { useState, useMemo } from "react";
import emailjs from "emailjs-com";
import { Helmet } from "react-helmet";

const initialState = {
  name: "",
  email: "",
  phone: "",
  message: "",
};

export const Contact = (props) => {
  const [{ name, email, phone, message }, setState] = useState(initialState);
  const currentYear = useMemo(() => new Date().getFullYear(), []);
  const placeholders = useMemo(() => props.data?.placeholder || [], [props.data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();

    // Enhanced validation
    if (!name || !email || !phone || !message) {
      alert('Please fill out all fields.');
      return;
    }

    // Prepare the data to send
    const data = {
      name: name,
      email: email,
      phone: phone,
      message: message,
    };

    // Send the POST request to Django
    fetch('http://127.0.0.1:8000/order/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
        .then((response) => response.json())
        .then((data) => {
          console.log('Success:', data);
          clearState();
          alert('Your message has been sent successfully.');
        })
        .catch((error) => {
          console.error('Failed to send the message:', error);
          alert('Failed to send the message. Please try again later.');
        });
  };

  return (
      <div>
        <Helmet>
          <title>Contact Us - FlexiForm</title>
          <meta name="description" content="Get in touch with FlexiForm. Contact us for more information about our services or to get a quote. We are here to assist you with all your needs." />
          <meta name="keywords" content="contact, FlexiForm, services, quote, support" />
          <meta property="og:title" content="Contact Us - FlexiForm" />
          <meta property="og:description" content="Get in touch with FlexiForm. Contact us for more information about our services or to get a quote. We are here to assist you with all your needs." />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.flexiform.com/contact" />
          <meta property="og:image" content="https://www.flexiform.com/public/Contact-us.png" />
        </Helmet>

        <div id="contact" className="smooth-scroll-container">
          <div className="container">
            <div className="col-md-8">
              <div className="row">
                <div className="section-title">
                  <h2>{props.data.title}</h2>
                  <p>{props.data.sub_title}</p>
                </div>
                <form name="sentMessage" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="name" className="sr-only">Name</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            className="form-control"
                            placeholder={placeholders[0] || "Your Name"}
                            required
                            onChange={handleChange}
                            value={name}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="phone" className="sr-only">Phone</label>
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            className="form-control"
                            placeholder={placeholders[1] || "Your Phone"}
                            required
                            onChange={handleChange}
                            value={phone}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="email" className="sr-only">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            className="form-control"
                            placeholder={placeholders[2] || "Your Email"}
                            required
                            onChange={handleChange}
                            value={email}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="message" className="sr-only">Message</label>
                    <textarea
                        name="message"
                        id="message"
                        className="form-control"
                        rows="4"
                        placeholder={placeholders[3] || "Your Message"}
                        required
                        onChange={handleChange}
                        value={message}
                    ></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                  <div id="success"></div>
                  <button type="submit" className="btn btn-custom btn-lg send_message_button">
                    {props.data.send_message}
                  </button>
                </form>
              </div>
            </div>
            <div className="col-md-3 col-md-offset-1 contact-info">
              <div className="contact-item">
                <h3>{props.data.contact_info}</h3>
                <p>
                <span>
                  <a href={"https://maps.app.goo.gl/DPAfSv29AfYV4YtH9"}
                     className="fa fa-map-marker"
                     target="_blank"
                  >
                  {"   "}{props.data.address}
                  </a>
                </span>


                </p>
              </div>
              <div className="contact-item">
                <p>
                  <a href={`tel:${props.data.phone}`}>
                    <i className="fa fa-phone"></i> {props.data.phone}
                  </a>
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <a href={`mailto:${props.data.email}`}>
                    <i className="fa fa-envelope-o"></i> {props.data.email}
                  </a>
                </p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="social">
                  <ul>
                    <li>
                      <a href={props.data.facebook}>
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    {/*<li>*/}
                    {/*  <a href={props.data.twitter}>*/}
                    {/*    <i className="fa fa-twitter"></i>*/}
                    {/*  </a>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*  <a href={props.data.youtube}>*/}
                    {/*    <i className="fa fa-youtube"></i>*/}
                    {/*  </a>*/}
                    {/*</li>*/}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="footer">
          <div className="container text-center">
            <p>&copy; {currentYear} FlexiForm</p>
          </div>
        </div>
      </div>
  );
};
