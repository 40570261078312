import React from "react";
import { Helmet } from "react-helmet";
import { Image } from "./image";

export const Gallery = ({ data }) => {
    return (
        <>
            <Helmet>
                <title>{data?.title || "Gallery"}</title>
                <meta name="description" content={data?.sub_title || "Gallery of our projects"} />
                <meta property="og:title" content={data?.title || "Gallery"} />
                <meta property="og:description" content={data?.sub_title || "Gallery of our projects"} />
                <meta property="og:type" content="website" />
                <meta property="og:image" content={data?.gallery_items?.[0]?.smallImage || "/default-image.jpg"} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={data?.title || "Gallery"} />
                <meta name="twitter:description" content={data?.sub_title || "Gallery of our projects"} />
                <meta name="twitter:image" content={data?.gallery_items?.[0]?.smallImage || "/default-image.jpg"} />
            </Helmet>
            <div id="portfolio" className="text-center smooth-scroll-container">
                <div className="container">
                    <div className="section-title">
                        <h2>{data?.title || "Gallery"}</h2>
                        <p>{data?.sub_title}</p>
                    </div>
                    <div className="row">
                        <div className="portfolio-items">
                            {data?.gallery_items ? (
                                data.gallery_items.map((item, index) => (
                                    <div key={`${item.title}-${index}`} className="col-sm-6 col-md-4 col-lg-4 mb-4">
                                        <Image title={item.title} info={item.info} smallImage={item.smallImage} />
                                    </div>
                                ))
                            ) : (
                                <div className="loading-container">
                                    <p>Loading gallery items...</p>
                                    {/* Optional: Add a spinner or placeholder images */}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
