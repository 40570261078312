import React, { useRef } from "react";
import { ReactComponent as Logo } from '../assets/logo/flexiformlogo.svg';
import Flag from 'react-flagkit';

export const Navigation = ({ handleLanguageChange, currentLanguage, data }) => {
  const dropdownRef = useRef(null);

  const closeDropdown = (targetId) => {
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    }

    if (dropdownRef.current) {
      dropdownRef.current.classList.remove('in');
      // If using Bootstrap 4 or later, use 'show' class instead
      dropdownRef.current.classList.remove('show');
    }

  };

  const smoothScroll = () => {

  };

  const handleNavClick = (event, targetId) => {
    event.preventDefault();
    closeDropdown();
    smoothScroll(targetId);
  };

  return (
      <nav id="menu" className="navbar navbar-default navbar-fixed-top">
        <div className="container">
          <div className="navbar-header">
            <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#bs-example-navbar-collapse-1"
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <a className="navbar-brand page-scroll logo_section">
              <Logo width="180px" />
            </a>
          </div>

          <div
              className="collapse navbar-collapse"
              id="bs-example-navbar-collapse-1"
              ref={dropdownRef}
          >
            <ul className="nav navbar-nav navbar-right">
              <li onClick={(e) => closeDropdown(e, 'features')} >
                <a href="#features" className="page-scroll">
                  {data.title_1}
                </a>
              </li>
              <li onClick={(e) => closeDropdown(e, 'about')}>
                <a href="#about" className="page-scroll">
                  {data.title_2}
                </a>
              </li>
              <li onClick={(e) => closeDropdown(e, 'services')}>
                <a href="#services" className="page-scroll">
                  {data.title_3}
                </a>
              </li>
              <li onClick={(e) => closeDropdown(e, 'portfolio')}>
                <a href="#portfolio" className="page-scroll" >
                  {data.title_4}
                </a>
              </li>
              <li onClick={(e) => closeDropdown(e, 'contact')}>
                <a href="#contact" className="page-scroll">
                  {data.title_5}
                </a>
              </li>
              {/* Language Selection */}
              <li className="dropdown" ref={dropdownRef}>
                <a
                    href="#"
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                  {currentLanguage === 'hy' ? (
                      <Flag country="AM" className="flag-icon" />
                  ) : (
                      <Flag country="GB" className="flag-icon" />
                  )}
                  <span className="caret"></span>
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a href="#"
                       onClick={() => {
                         handleLanguageChange("en");
                         closeDropdown();
                       }}>
                      <Flag country="GB" className="flag-icon" /> English
                    </a>
                  </li>
                  <li>
                    <a href="#"
                       onClick={() => {
                         handleLanguageChange("hy");
                         closeDropdown();
                       }}>
                      <Flag country="AM" className="flag-icon" /> Հայերեն
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
  );
};