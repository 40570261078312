import React, { useState, useEffect, useMemo } from 'react';
import { Carousel } from 'react-bootstrap';
import { TypeAnimation } from 'react-type-animation';
import banner1 from '../assets/banners/banner_1.jpg';
import banner2 from '../assets/banners/banner_2.jpg';
import banner3 from '../assets/banners/banner_3.jpg';

export const Banner = (props) => {
    const images = useMemo(() => [banner1, banner2, banner3], []);
    const texts = props.data?.animated_text || [];
    const [index, setIndex] = useState(0);
    const [animationKey, setAnimationKey] = useState(Date.now());

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 5000); // Change slide every 5 seconds

        return () => clearInterval(interval);
    }, [images]);

    useEffect(() => {
        setAnimationKey(Date.now()); // Update animation key when index changes
    }, [index]);

    return (
        <div className="carouselContainer">
            <Carousel
                className="carousel_section"
                activeIndex={index}
                onSelect={handleSelect}
                interval={null}
                fade
            >
                {images.map((image, idx) => (
                    <Carousel.Item key={idx} className="carouselItem">
                        <img
                            loading="lazy"
                            className="carouselImg"
                            src={image}
                            alt={`Slide ${idx + 1}`}
                        />
                        <Carousel.Caption className="carouselCaption carousel_text_section">
                            <div className="intro-text">
                                <h1>
                                    {props.data?.title || "Loading"}
                                    <span></span>
                                </h1>
                                {texts.length > 0 && (
                                    <p className="animated_text">
                                        <TypeAnimation
                                            key={animationKey}
                                            sequence={[
                                                texts[index] || "Loading...",
                                                5000
                                            ]}
                                            speed={200}
                                            repeat={Infinity}
                                            style={{
                                                whiteSpace: 'pre-line',
                                                fontSize: '2em',
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                            }}
                                        />
                                    </p>
                                )}
                                <a
                                    href="#features"
                                    className="btn btn-custom btn-lg page-scroll"
                                >
                                    {props.data?.learn_more_button || "Learn More"}
                                </a>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    );
};
