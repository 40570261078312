// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { LanguageProvider } from "../src/components/LanguageContext";
import HomePage from "./components/Home";

const App = () => {
    return (
        <LanguageProvider>
            <Router>
                <Routes>
                    <Route path="/" element={<Navigate to="/hy" />} />
                    <Route path="/:lang" element={<HomePage />} />
                </Routes>
            </Router>
        </LanguageProvider>
    );
};

export default App;