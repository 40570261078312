import React from "react";
import { Helmet } from "react-helmet";

export const About = (props) => {
  const { data } = props;

  return (
      <div id="about">
        <Helmet>
          <title>{data?.title || "About Us"}</title>
          <meta name="description" content={data?.paragraph || "Learn more about us"} />
          <meta property="og:title" content={data?.title || "About Us"} />
          <meta property="og:description" content={data?.paragraph || "Learn more about us"} />
          <meta property="og:image" content="img/about.jpg" />
          <meta property="og:url" content={window.location.href} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={data?.title || "About Us"} />
          <meta name="twitter:description" content={data?.paragraph || "Learn more about us"} />
          <meta name="twitter:image" content="img/about.jpg" />
        </Helmet>
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6">
              <img
                  src="img/about.jpg"
                  className="img-responsive"
                  alt={data?.title || "About us"}
              />
            </div>
            <div className={"about_text"}>
              <div className="col-xs-12 col-md-6">
                <div className="about-text">
                  <h2>{data?.title || "Loading..."}</h2>
                  <p>{data?.paragraph || "Loading..."}</p>
                  <h3>{data?.why_choose_us || "Loading..."}</h3>
                  <div className="list-style">
                    <div className="col-lg-6 col-sm-6 col-xs-12">
                      <ul>
                        {data?.Why?.map((d, i) => (
                            <li key={`${d}-${i}`}>{d}</li>
                        )) || "Loading"}
                      </ul>
                    </div>
                    <div className="col-lg-6 col-sm-6 col-xs-12">
                      <ul>
                        {data?.Why2?.map((d, i) => (
                            <li key={`${d}-${i}`}>{d}</li>
                        )) || "Loading"}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};
