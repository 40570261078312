import React from "react";
import { Helmet } from "react-helmet";

export const Image = ({ title, info, largeImage, smallImage }) => {
  return (
      <div className="portfolio-item">
        <Helmet>
          <title>{title || "Product"}</title>
          <meta name="description" content={info || "Product description"} />
          <meta property="og:title" content={title || "Product"} />
          <meta property="og:description" content={info || "Product description"} />
          <meta property="og:image" content={largeImage || smallImage} />
          <meta property="og:url" content={window.location.href} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={title || "Product"} />
          <meta name="twitter:description" content={info || "Product description"} />
          <meta name="twitter:image" content={largeImage || smallImage} />
        </Helmet>
        <div className="hover-bg">
          <a href={largeImage} title={title} data-lightbox-gallery="gallery1">
            <div className="hover-text">
              <h4>{title}</h4>
              <p>{info}</p>
            </div>
            <img src={smallImage} className="img-responsive" alt={title} />
          </a>
        </div>
      </div>
  );
};
